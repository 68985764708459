<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Promotion Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/inventory/promotion-items/create">
          <v-icon left dark>mdi-plus</v-icon> New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="promoItems"
              :search="search"
            >
              <template v-slot:item.id="{ item, index }">
                <td>{{ index + 1 }}</td>
              </template>
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.action="props">
                <v-btn :to="`/inventory/promotion-items/${props.item.id}`" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    promoItems: [],
    headers: [
      { text: "#", value: "id" },
      { text: "Name", value: "Name" },
      { text: "Code", value: "Code" },
      { text: "Quantity", value: "Quantity" },
      { text: "PriceList", value: "PriceList" },
      { text: "Warehouse", value: "ToWH" },
      { text: "ValidFrom", value: "ValidFrom" },
      { text: "ValidTo", value: "ValidTo" },
      { text: "Action", value: "action" },
    ],
    BinLocations: [],
  }),
  methods: {
    getPromoItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/promo-items`)
        .then((res) => {
          self.promoItems = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getPromoItems();
  },
};
</script>
